import React, { Component } from "react";
import "./privacy.css";
import { Link, NavLink } from "react-router-dom";
import { Whatsapp } from "../Home/Whatsapp";
export class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <div class="ht-mega-slider">
            <div class="ht-mega-slider-activation">
              <div class="ht-slide slider-style-1 bg_image--88-feature d-flex align-items-center animation__style--1 feature-slider-style-here">
                <div class="container">
                  <div class="row align-items-center text-center">
                    <div class="col-xl-12 col-lg-12 col-md-6 col-12">
                      <div class="hero-software content hero-feature-content">
                        <h4 style={{ color: "#fff" }}>
                          Terms &amp; Conditions
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.{" "}
                        </p>
                        {/* <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn" href="#">
                            DOWNLOAD
                          </a>
                        </div> */}
                      </div>
                    </div>
                    {/* <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                      <div class="thumb text-right software-slide-thumb">
                        <img
                          src={require("../img/software/logo/slider.png")}
                          alt="slider"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-buy-content">
            <div className="container">
              <div className="buy-content-upper">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="custom-privacy-content">
                      <p>
                        PLEASE READ THESE TERMS OF USE (“AGREEMENT”) CAREFULLY
                        BEFORE USING THE SERVICES OFFERED BY TMS TRAVEL, INC.
                        (“TMS,” “WE,” “US,” “OUR”). BY VISITING THE WEBSITES OR
                        USING THE SERVICES IN ANY MANNER, YOU AGREE THAT YOU
                        HAVE READ AND AGREE TO BE BOUND BY AND A PARTY TO THE
                        TERMS AND CONDITIONS OF THIS AGREEMENT TO THE EXCLUSION
                        OF ALL OTHER TERMS. IF THE TERMS OF THIS AGREEMENT ARE
                        CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO
                        SUCH TERMS. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL
                        THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU HAVE NO
                        RIGHT TO USE THE WEBSITE OR SERVICES. USE OF TMS's
                        SERVICES IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO
                        ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, TO THE
                        EXCLUSION OF ALL OTHER TERMS..
                      </p>
                    </div>
                    <div className="terms-cookie-para">
                      <ul>
                        <li>
                          {" "}
                          <strong>ACCESS TO THE SERVICES</strong>
                        </li>
                        <p>
                          The peek.com website and domain name and subdomains,
                          and features, content, or application services
                          (including without limitation any mobile application
                          services) offered from time to time by TMSin
                          connection therewith (collectively, the “Website”) are
                          owned and operated by Peek. Subject to the terms and
                          conditions of this Agreement, TMS may offer to provide
                          certain services, as described more fully on the
                          Website, and that have been selected by you (together
                          with the Website, the “Services”), solely for your own
                          use, and not for the use or benefit of any third
                          party. The term “Services” includes, without
                          limitation, use of the Website, any service
                          TMSperforms for you and the Content (as defined below)
                          offered by TMSon the Website. TMS may change, suspend
                          or discontinue the Services at any time, including the
                          availability of any feature, database, or Content. TMS
                          may also impose limits on certain features and
                          services or restrict your access to parts or all of
                          the Services without notice or liability. TMS reserves
                          the right, in its sole discretion, to modify this
                          Agreement at any time by posting a notice on the
                          Website, or by sending you a notice via email or
                          postal mail. You shall be responsible for reviewing
                          and becoming familiar with any such modifications.
                          Your use of the Services following such notification
                          constitutes your acceptance of the terms and
                          conditions of this Agreement as modified. .
                        </p>
                        <p>
                          TMS does not knowingly collect or solicit personal
                          information from anyone under the age of 13 or
                          knowingly allow such persons to register for the
                          Services. If you are under 13, please do not attempt
                          to register for the Services or send any information
                          about yourself to us, including your name, address,
                          telephone number, or email address. No one under age
                          13 may provide any personal information to TMS or on
                          the Services. In the event that we learn that we have
                          collected personal information from a child under age
                          13 without verification of parental consent, we will
                          delete that information as quickly as possible. If you
                          believe that we might have any information from or
                          about a child under 13, please contact us at
                          support@peek.com.
                        </p>
                        <p>
                          You represent and warrant to TMSthat: (i) you are an
                          individual (i.e., not a corporation) and you are of
                          legal age to form a binding contract or have your
                          parent’s permission to do so, and you are at least 13
                          years or age or older; (ii) all registration
                          information you submit is accurate and truthful; and
                          (iii) you will maintain the accuracy of such
                          information. You also certify that you are legally
                          permitted to use and access the Services and take full
                          responsibility for the selection and use of and access
                          to the Services. This Agreement is void where
                          prohibited by law, and the right to access the
                          Services is revoked in such jurisdictions.
                        </p>

                        <li>
                          <strong>WEBSITE AND SERVICES CONTENT</strong>
                        </li>
                        <p>
                          The Website, the Services, and their contents are
                          intended solely for the personal, non-commercial use
                          of Services users and may only be used in accordance
                          with the terms of this Agreement. All materials
                          displayed or performed on the Services (including, but
                          not limited to text, graphics, articles, photographs,
                          images, illustrations (also known as the “Content,”
                          and which includes User Submissions (as defined
                          below))) are protected by copyright. You shall abide
                          by all copyright notices, trademark rules,
                          information, and restrictions contained in any Content
                          accessed through the Services, and shall not use,
                          copy, reproduce, modify, translate, publish,
                          broadcast, transmit, distribute, perform, upload,
                          display, license, sell or otherwise exploit for any
                          purposes whatsoever any Content or third party
                          submissions or other proprietary rights not owned by
                          you: (i) without the express prior written consent of
                          the respective owners, and (ii) in any way that
                          violates any third party right.
                        </p>
                        <p>
                          The Website and the Services are protected by
                          copyright as collective works and/or compilations,
                          pursuant to U.S. copyright laws, international
                          conventions, and other copyright laws. You may not
                          modify, publish, transmit, participate in the transfer
                          or sale of, reproduce (except as expressly provided in
                          this Section 2), create derivative works based on,
                          distribute, perform, display, or in any way exploit,
                          any of the Content, software, materials, or Services
                          in whole or in part.
                        </p>
                        <p>
                          You may download or copy the Content (and other items
                          displayed on the Website or Services for download) for
                          personal non-commercial use only, provided that you
                          maintain all copyright and other notices contained in
                          such Content. You shall not store any significant
                          portion of any Content in any form. Copying or storing
                          of any Content other than personal, noncommercial use
                          is expressly prohibited without prior written
                          permission from TMSor from the copyright holder
                          identified in such Content’s copyright notice. If you
                          link to the Website, TMS may revoke your right to so
                          link at any time, at TMS's sole discretion. TMS
                          reserves the right to require prior written consent
                          before linking to the Website.
                        </p>
                        <p>
                          In the course of using the Services, you and other
                          users may provide information which may be used by
                          TMSin connection with the Services and which may be
                          visible to certain other users. You understand that by
                          posting information or content on the Website or
                          otherwise providing content, materials or information
                          to TMSor in connection with the Services
                          (collectively, “User Submissions”), TMShereby is and
                          shall be granted a non exclusive, worldwide, royalty
                          free, perpetual, irrevocable, sublicenseable and
                          transferable right to fully exploit such User
                          Submissions (including all related intellectual
                          property rights) in connection with the Services, the
                          Website, and TMS's (and its successors’ and assigns’)
                          business, including without limitation for promoting
                          and redistributing part or all of the Services (and
                          derivative works thereof) in any media formats and
                          through any media channels; however, TMS will only
                          share your personally identifiable information in
                          accordance with TMS's privacy policy in effect from
                          time to time and located at Privacy Policy. You also
                          hereby do and shall grant each user of the Services a
                          non-exclusive license to access your User Submissions
                          through the Services, and to use, modify, reproduce,
                          distribute, prepare derivative works of, display and
                          perform such User Submissions as permitted through the
                          functionality of the Services and under this
                          Agreement. Furthermore, you understand that TMSretains
                          the right to reformat, modify, create derivative works
                          of, excerpt, and translate any User Submissions
                          submitted by you. For clarity, the foregoing license
                          grant to TMS does not affect your ownership of or
                          right to grant additional non-exclusive licenses to
                          the material in your User Submissions, unless
                          otherwise agreed in writing.
                        </p>
                        <p>
                          You understand that all information publicly posted or
                          privately transmitted through the Services is the sole
                          responsibility of the person from which such content
                          originated and that TMS will not be liable for any
                          errors or omissions in any content. You understand
                          that TMScannot guarantee the identity of any other
                          users with whom you may interact in the course of
                          using the Services. Additionally, TMScannot guarantee
                          the authenticity of any data which users or merchants
                          may provide about themselves. You acknowledge that all
                          Content accessed by you using the Services is at your
                          own risk and you will be solely responsible for any
                          damage or loss to any party resulting therefrom.
                        </p>
                        <p>
                          Under no circumstances will TMS be liable in any way
                          for any Content, including, but not limited to, any
                          errors or omissions in any Content, or any loss or
                          damage of any kind incurred in connection with use of
                          or exposure to any Content posted, emailed, accessed,
                          transmitted, or otherwise made available via the
                          Services.
                        </p>
                        <li>
                          <strong>RESTRICTIONS</strong>
                        </li>
                        <p>
                          You warrant, represent and agree that you will not
                          contribute any Content or otherwise use the Services
                          in a manner that (i) infringes or violates the
                          intellectual property rights or proprietary rights,
                          rights of publicity or privacy, or other rights of any
                          third party; (ii) violates any law, statute, ordinance
                          or regulation; (iii) is harmful, fraudulent,
                          deceptive, threatening, abusive, harassing, tortious,
                          defamatory, vulgar, obscene, libelous, or otherwise
                          objectionable; (iv) involves commercial activities
                          and/or sales without TMS's prior written consent such
                          as contests, sweepstakes, barter, advertising, or
                          pyramid schemes; (v) impersonates any person or
                          entity, including without limitation any employee or
                          representative of TMS or (vi) contains a virus, trojan
                          horse, worm, time bomb, or other harmful computer
                          code, file, or program. TMS reserves the right to
                          remove any Content from the Services at any time, for
                          any reason (including, but not limited to, upon
                          receipt of claims or allegations from third parties or
                          authorities relating to such Content or if TMS is
                          concerned that you may have breached the immediately
                          preceding sentence), or for no reason at all. You, not
                          Peek, remain solely responsible for all Content that
                          you upload, post, email, transmit, or otherwise
                          disseminate using, or in connection with, the
                          Services, and you warrant that you possess all rights
                          necessary to provide such content to TMS and to grant
                          TMS the rights to use such information in connection
                          with the Services and as otherwise provided herein.
                        </p>
                        <p>
                          You acknowledge that you shall be solely responsible
                          for your own User Content and the consequences of
                          posting or publishing all of your User Content on the
                          Website. You represent and warrant that: (i) you own
                          or have the necessary rights, licenses, consents,
                          releases and/or permissions to use and authorize TMS
                          to use all copyright, trademark or other proprietary
                          rights in and to any User Content to enable inclusion
                          and use thereof as contemplated by the Site and these
                          Terms; (ii) neither the User content nor your posting,
                          uploading, publication, submission or transmittal of
                          the User Content or TMS Use of the User Content (or
                          any portion thereof) on or through the Site and/or the
                          services offered through the Site will infringe,
                          misappropriate or violate any third party’s patent,
                          copyright, trademark, trade secret, moral rights or
                          other proprietary or intellectual property rights, or
                          rights of publicity or privacy, or result in the
                          violation of any applicable law, rule or regulation.
                        </p>
                        <p>
                          You are responsible for all of your activity in
                          connection with the Services. Any fraudulent, abusive,
                          or otherwise illegal activity may be grounds for
                          termination of your right to access or use the
                          Services. You may not post or transmit, or cause to be
                          posted or transmitted, any communication or
                          solicitation designed or intended to obtain password,
                          account, or private information from any other user of
                          the Services. Use of the Services to violate the
                          security of any computer network, crack passwords or
                          security encryption codes, transfer or store illegal
                          material (including material that may be considered
                          threatening or obscene), or engage in any kind of
                          illegal activity is expressly prohibited. You will not
                          run Maillist, Listserv, any form of auto-responder, or
                          “spam” on the Services, or any processes that run or
                          are activated while you are not logged on to the
                          Website, or that otherwise interfere with the proper
                          working of or place an unreasonable load on the
                          Services’ infrastructure. Further, the use of manual
                          or automated software, devices, or other processes to
                          “crawl,” “scrape,” or “spider” any page of the Website
                          is strictly prohibited. You will not decompile,
                          reverse engineer, or otherwise attempt to obtain the
                          source code of the Services. You will be responsible
                          for withholding, filing, and reporting all taxes,
                          duties and other governmental assessments associated
                          with your activity in connection with the Services.
                        </p>
                        <p>
                          YOU ACKNOWLEDGE AND AGREE THAT TMSIS NOT A PARTY TO
                          ANY AGREEMENTS ENTERED INTO BETWEEN PROVIDERS AND
                          GUESTS, HAS NO CONTROL OVER THE CONDUCT OF THE
                          PROVIDERS OR THEIR SERVICES, AND DISCLAIMS ANY AND ALL
                          LIABILITY ASSOCIATED WITH THE USE OF PROVIDERS’
                          SERVICES.
                        </p>
                        <p>
                          How the Service Works. The Service can be used to
                          facilitate the listing of Provider’s services and the
                          booking of said services by Guests. TMS makes
                          available a platform or marketplace for Guests and
                          Providers to meet online and make a reservation
                          related to Provider’s services (a “Booking”). TMS is
                          not an owner or operator of the Provider or any
                          associated rental property, including, but not limited
                          to, boats, equipment, nor is it a provider of the
                          underlying activities, classes or tours, and TMS does
                          not own, sell, furnish, provide, rent, manage or
                          control Provider’s products and services. TMS's
                          responsibilities are limited to (a) facilitating the
                          availability of our Service to make Bookings and (b)
                          serving as the limited agent of each Provider for the
                          purpose of accepting payments from Guests on behalf of
                          the Provider.
                        </p>
                        <p>
                          Bookings. As a condition of your use of this Service,
                          you warrant that (a) you are 16 years old or older if
                          you are a Guest and 18 years old or older if you are a
                          Provider or Booking Agent’s representative ; (b) you
                          possess the legal authority to create a binding legal
                          obligation; (c) you will use this Service in
                          accordance with this Agreement; (d) you will only use
                          this Service to make legitimate listing and/or
                          Bookings for you or for another person for whom you
                          are legally authorized to act; (e) you will not make
                          any speculative, false or fraudulent listings or
                          Bookings; (f) you will inform such other persons about
                          the terms and conditions that apply to the Bookings,
                          including all applicable rules and restrictions, and
                          obtain their agreement to be bound by the terms and
                          conditions; (g) all information supplied by you is
                          true, accurate, current and complete, (h) you will not
                          use the Service to find a Provider or Guest and then
                          complete the transaction independent of the Service,
                          attempt to circumvent any obligation to pay any fee to
                          TMS , or make any Bookings in the anticipation of
                          demand, and (i) if you have a TMS .com account, you
                          will safeguard your account information and will be
                          responsible for any use of your account by you and
                          anyone other than you.
                        </p>
                        <p>
                          YOU ACKNOWLEDGE THAT TMS DOES NOT CONTROL THE CONTENT
                          CONTAINED IN ANY LISTINGS OR BOOKING AND MAKES NO
                          REPRESENTATIONS OR GUARANTEES ABOUT THE CONDITION,
                          LEGALITY OR SUITABILITY OF ANY PRODUCT AND SERVICES
                          OFFERED BY PROVIDER. TMS IS NOT RESPONSIBLE FOR AND
                          DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND ALL
                          LISTINGS, BOOKINGS AND PROVIDER’S PRODUCTS AND
                          SERVICES. THE USE OF PROVIDER’S PRODUCTS AND SERVICES
                          IS AT THE GUEST’S OWN RISK.
                        </p>
                        <p>
                          The Providers, not TMS , are solely responsible for
                          honoring any Bookings and making available any product
                          and services reserved through our Service. You agree
                          and understand that you may be required to enter into
                          an agreement with the Provider and that your Booking
                          is contingent upon you agreeing to accept any terms,
                          conditions, rules and restrictions imposed by the
                          Provider. You acknowledge and agree that you, and not
                          TMS , will be responsible for performing the
                          obligations of any such agreements and that TMS is not
                          a party to such agreements. For more information about
                          TMS 's seller of travel registration, please go here.
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Whatsapp />
        </div>
        {/*Buy Main Content */}

        {/*end buy main content */}
      </React.Fragment>
    );
  }
}

export default Terms;

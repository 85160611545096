import React, { Component } from "react";
import "./contact.css";
import { Link } from "react-router-dom";
import { Whatsapp } from "../Home/Whatsapp";
export class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <div id="wrapper" className="wrapper">
            <div class="ht-mega-slider">
              <div class="ht-mega-slider-activation">
                <div class="ht-slide slider-style-1 bg_image--88--contact d-flex align-items-center animation__style--1 custom-slider-style-here">
                  <div class="container">
                    <div class="row align-items-center text-left">
                      <div class="col-xl-7 col-lg-7 col-md-6 col-12">
                        <div class="hero-software content">
                          <h4 style={{ color: "#fff" }}>Contact Us</h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.{" "}
                          </p>
                          {/* <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn" href="#">
                            DOWNLOAD
                          </a>
                        </div> */}
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                        <div class="thumb text-right software-slide-thumb">
                          <img
                            src={require("../img/software/logo/slider.png")}
                            alt="slider"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <main className="page-content">
              {/*features here */}
              <section className="custom-features-section">
                <div class="section-service section-ptb-120 custom-bg-color-features-contact">
                  <div class="container">
                    {/* <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h2>Contact Us</h2>
                        <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        />
                        <p>
                        Find Us By Bellow Details
                        </p>
                      </div>
                    </div>
                  </div> */}
                    {/* <div class="row mt--40">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center center-block">
                      <div class="service text-left service-6 custom-service-contact-box" data-toggle="modal" data-target="#exampleModal">
                            <div className="row">
                                <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="icons">

                     
                     <i class="fa fa-map-marker" aria-hidden="true"></i>
                          </div></div>
                        </div>
    
                        <div class="content text-center">
                        
                          <p>
                          8-54 Paya Lebar Square <br />
60 Paya Lebar Roa SG, Singapore
                          </p>
                        </div>
                      </div>
                    </div>

                    

                    

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                      <div class="service text-left service-6 custom-service-contact-box">
                      <div className="row">
                                <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="icons">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        </div></div>
                        </div>
                        <div class="content text-center">
                        
                          <p>
                          +65 93901336 <br />
+65 93901337
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                      <div class="service text-left service-6 custom-service-contact-box">
                      <div className="row">
                                <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="icons">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div></div>
                        </div>
                        <div class="content text-center">
                          
                          <p>
                          yourmail@gmail.com <br />
backpiper.com@gmail.com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="form-contact-here">
                          <form autoComplete="OFF">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="inputEmail4"
                                  placeholder="Your firstname"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="inputPassword4"
                                  placeholder="Your lastname"
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <input
                                type="email"
                                class="form-control"
                                id="inputAddress"
                                placeholder="Your email address"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="inputAddress2"
                                placeholder="Your subject of this message"
                              />
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-12">
                                <textarea
                                  className="form-control"
                                  rows="11"
                                  cols="10"
                                  placeholder="Say somwthing about us"
                                />
                              </div>
                            </div>

                            <button
                              type="submit"
                              class="btn btn-primary custom-send-btn"
                            >
                              Send Message
                            </button>
                          </form>
                        </div>
                      </div>

                      {/*side content here */}
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="custom-side-box-first">
                          <div className="col-lg-12">
                            <div class="contact-info-wrap-flex">
                              <div class="con-info">
                                <p>
                                  <span>
                                    <i
                                      class="fa fa-compass"
                                      aria-hidden="true"
                                    />
                                  </span>{" "}
                                  198 West 21th Street, <br /> Suite 721 New
                                  York NY 10016
                                </p>
                              </div>
                              <div class="con-info">
                                <p>
                                  <span>
                                    <i
                                      class="fa fa-mobile"
                                      aria-hidden="true"
                                    />
                                  </span>{" "}
                                  <a href="tel://1234567920">+ 1235 2355 98</a>
                                </p>
                              </div>
                              <div class="con-info">
                                <p>
                                  <span>
                                    <i
                                      class="fa fa-paper-plane-o"
                                      aria-hidden="true"
                                    />
                                  </span>{" "}
                                  <a href="mailto:info@yoursite.com">
                                    info@yoursite.com
                                  </a>
                                </p>
                              </div>
                              <div class="con-info">
                                <p>
                                  <span>
                                    <i class="fa fa-globe" aria-hidden="true" />
                                  </span>{" "}
                                  <a href="#">yourwebsite.com</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end side content here */}
                    </div>
                  </div>
                </div>
              </section>

              {/*end features here */}
              <Whatsapp />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          {/* <section className="custom-subscribe">
              <div className="section-newsletter section-ptb-120 bg_color--47">
                <div className="container">
                  <div className="row newsletter-style-4 align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="title">
                        <p>Sign Up Here to get Letest Update</p>
                        <h2>Subscribe to Newsletter.</h2>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <form action="#">
                        <div className="input-box">
                          <input type="email" placeholder="Enter Your Email*" />
                          <button type="submit">
                            <i className="fa fa-paper-plane-o" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          <footer class="footer">
            <div class="copyright copyright-4 st-copyright custom-footer-bg">
              <div class="container">
                {/* <div className="row newsletter-style-4 align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="title">
                        <p>Sign Up Here to get Letest Update</p>
                        <h2>Subscribe to Newsletter.</h2>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <form action="#">
                        <div className="input-box">
                          <input type="email" placeholder="Enter Your Email*" />
                          <button type="submit">
                            <i className="fa fa-paper-plane-o" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                <div class="row align-items-center">
                  <div class="col-lg-12 col-12">
                    <div className="custom-footer-links text-center">
                      <ul className="list-inline">
                      <li className="list-inline-item">Our Clients</li>
                        <li className="list-inline-item">Company Information</li>
                        <li className="list-inline-item">Careers</li>
                        <li className="list-inline-item">Raise a grievance</li>
                        <li className="list-inline-item"><Link to ="/Terms">Terms of use</Link></li>
                        <li className="list-inline-item"><Link to ="/Privacy">Privacy Policy</Link></li>
                      </ul>
                    </div>
                    {/* <div className="custom-footer-links2 text-center">
                    <ul className="list-inline">
      <li className="list-inline-item"><a href={'https://www.facebook.com/'}><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://twitter.com/'}><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://in.pinterest.com/'}><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://www.youtube.com/'}><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
  </ul>
                    </div> */}
                    <div class="copyright-left content text-center">
                      <div class="logo">
                        <a href="#">
                          <img
                            src={require("../img/software/logo/logo.png")}
                            alt="portfolio image"
                          />
                        </a>
                      </div>
                      <p>
                        Copyright © 2019. All Right Reserved. Design By{" "}
                        <a href={"https://www.fsjars.com/"}>
                          <strong>FSJARS</strong>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
export class Navbar extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="wrapper">
          <header
            className="header header-1 header--transparant software-header"
            id="custom-navbar-row"
          >
            <div className="container">
              <div className="align-items-center justify-content-between">
                <nav class="navbar navbar-expand-lg navbar-dark static-top mainmenu_nav">
                  <div class="container">
                    <div className="cuscol">
                      <div className="logo">
                        <a href={"/"}>
                          {/* <Link to="/"> */}
                          {/* <img src={require("../img/logo/logo-w.png")} alt="logo" /> */}
                          <h2>TMS</h2>
                          {/* </Link> */}
                        </a>
                      </div>
                    </div>
                    {/* <a class="navbar-brand" href="#">
          <img src="http://placehold.it/150x50?text=Logo" alt="" />
        </a> */}
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarResponsive"
                      aria-controls="navbarResponsive"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon" />
                    </button>
                    <div
                      class="collapse navbar-collapse custom-collapse-bg"
                      id="navbarResponsive"
                    >
                      <ul class="navbar-nav ml-auto mainmenu">
                        {/* <li class="nav-item active">
                          <a class="nav-link" href="#">
                            Home
                            <span class="sr-only">(current)</span>
                          </a>
                        </li> */}
                        <li
                          class="nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <a class="nav-link" href={"/Features"}>
                            <Link to="/Features">Features</Link>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="#">
                            Pricing
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <div class="">
                            <div class="dropdown custom-mainmenu-dropdown">
                              <a class="nav-link custom-nav-a-link" href="#">
                                Support
                              </a>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a class="dropdown-item" href="#">
                                  FAQ
                                </a>
                                <a class="dropdown-item" href="#">
                                  User Manual
                                </a>
                                <a class="dropdown-item" href="#">
                                  Video Tutorial
                                </a>
                                <a class="dropdown-item" href="#">
                                  Open Support Ticket
                                </a>
                              </div>
                            </div>
                            {/* <div class="dropdown custom-mainmenu-dropdown">
 
  <a class="nav-link custom-nav-a-link" href="#">About Us</a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Our Clients</a>
    <a class="dropdown-item" href="#">Case Studies</a>
    <a class="dropdown-item" href="#">Why Us</a>
    <a class="dropdown-item" href="#">Career</a>
  </div>
</div> */}
                          </div>
                          {/* <div class="dropdown custom-mainmenu-dropdown">
  <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
    Dropdown button
  </button>
  <div class="dropdown-menu">
    <a class="dropdown-item" href="#">Link 1</a>
    <a class="dropdown-item" href="#">Link 2</a>
    <a class="dropdown-item" href="#">Link 3</a>
  </div>
</div> */}
                        </li>
                        <li
                          className="nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <div class="dropdown custom-mainmenu-dropdown">
                            <a class="nav-link custom-nav-a-link" href="#">
                              About Us
                            </a>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a class="dropdown-item" href="#">
                                Our Clients
                              </a>
                              <a class="dropdown-item" href="#">
                                Case Studies
                              </a>
                              <a class="dropdown-item" href={"/Why"}>
                                <Link to="/Why">Why Us</Link>
                              </a>
                              <a class="dropdown-item" href="#">
                                Career
                              </a>
                            </div>
                          </div>
                        </li>

                        <li
                          class="nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <a class="nav-link" href={"/Contact"}>
                            <Link to="/Contact">Contact Us</Link>
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a class="nav-link" href="#">
                            Log In
                          </a>
                        </li> */}
                        <li
                          className="nav-item custom-nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <button
                            type="button"
                            class="btn btn-primary custom-signin-btn"
                          >
                            Log In / Sign Up
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>

            {/* <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="cuscol">
                        <div className="logo">
                            <a href="#">
                                <img src={require("../img/logo/logo-w.png")} alt="logo" />
                            </a>
                        </div>
                    </div>
                    <div className="cuscol d-flex align-items-center">
                        <nav className="mainmenu_nav with-mega-menu forall-element-page d-none d-lg-block">
                            <ul className="mainmenu">
                                <li>
                                    <a href="#">Home</a>
                                </li>
                                <li className="drop withdropdown">
                                    <a href="#">Ready Pages</a>
                                  
                                    <div className="mega-menu dropdown">

                                        <ul className="item">
                                            <li><a href="index-cleaning-service.html">Cleaning Service</a></li>
                                            <li><a href="index-agency.html">Creative Agency</a></li>
                                            <li><a href="index-education.html">Education</a></li>
                                            <li><a href="index-construction.html">Construction</a></li>
                                            <li><a href="index-gardening.html">Gardening</a></li>
                                            <li><a href="index-event.html">Event</a></li>
                                            <li><a href="index-medical.html">Medical</a></li>
                                            <li><a href="index-portfolio.html">Potrtfolio</a></li>
                                            <li><a href="index-corporate.html">Corporate</a></li>
                                            <li><a href="index-repair.html">Repair</a></li>
                                        </ul>

                                        <ul className="item">
                                            <li><a href="index-political.html">Political</a></li>
                                            <li><a href="index-travel.html">Traveling</a></li>
                                            <li><a href="index-carpenter.html">Carpenter</a></li>
                                            <li><a href="index-charity.html">Charity</a></li>
                                            <li><a href="index-software.html">Software</a></li>
                                            <li><a href="index-resturant.html">Resturant</a></li>
                                            <li><a href="index-real-estate.html">Real Estate</a></li>
                                            <li><a href="index-hosting.html">Hosting</a></li>
                                            <li><a href="index-beauty-salon.html">Beauty Salon</a></li>
                                            <li><a href="index-body-fitness.html">Body Fitness</a></li>
                                        </ul>

                                    </div>
                                   
                                </li>
                            </ul>
                        </nav>
                        <div className="header-btn text-right">
                            <a className="cln-btn btn-transparent btn-radious--24" href="#">GET A QUOTE</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 d-none">
                        <nav className="mobilemenu__nav">
                            <ul className="meninmenu">
                                <li><a href="#">Home</a></li>
                                <li>
                                    <a href="#">Ready Pages</a>
                                    <ul>
                                        <li><a href="index-cleaning-service.html">Cleaning Service</a></li>
                                        <li><a href="index-agency.html">Creative Agency</a></li>
                                        <li><a href="index-education.html">Education</a></li>
                                        <li><a href="index-construction.html">Construction</a></li>
                                        <li><a href="index-gardening.html">Gardening</a></li>
                                        <li><a href="index-event.html">Event</a></li>
                                        <li><a href="index-medical.html">Medical</a></li>
                                        <li><a href="index-portfolio.html">Potrtfolio</a></li>
                                        <li><a href="index-corporate.html">Corporate</a></li>
                                        <li><a href="index-repair.html">Repair</a></li>
                                        <li><a href="index-political.html">Political</a></li>
                                        <li><a href="index-travel.html">Traveling</a></li>
                                        <li><a href="index-carpenter.html">Carpenter</a></li>
                                        <li><a href="index-charity.html">Charity</a></li>
                                        <li><a href="index-software.html">Software</a></li>
                                        <li><a href="index-resturant.html">Resturant</a></li>
                                        <li><a href="index-real-estate.html">Real Estate</a></li>
                                        <li><a href="index-hosting.html">Hosting</a></li>
                                        <li><a href="index-beauty-salon.html">Beauty Salon</a></li>
                                        <li><a href="index-body-fitness.html">Body Fitness</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                 
                <div className="mobile-menu d-block d-lg-none"></div>
                 
            </div> */}
          </header>
        </div>
      </React.Fragment>
    );
  }
}

export default Navbar;

import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
// import './plugins.css'
// import './style.css'
// import './custom.css'
import {Route} from 'react-router-dom'
import { Navbar } from './components/Navbar/Navbar';
import { Home } from './components/Home/Home';
import { Footer } from './components/Footer/Footer';
import { NewFile } from './components/Navbar/NewFile';
import  Features  from './components/Features/Features';
import { Contact } from './components/Contact/Contact';
import { Why } from './components/WhyUs/Why';
import { Privacy } from './components/Privacy/Privacy';
import { Terms } from './components/Privacy/Terms';

class App extends Component {
  render() {
    // let customroutes;
    // if(localStorage.length == 0)
    // {
    //   customroutes = (
    //     <div>something!!!</div>
    //   )
    // }
    // else
    // {
    //   customroutes = (
    //     <div>
    //   <Navbar />
    //   <Home />
    //   <Footer />
    //  </div>
    //   )
    // }
    return (
      // <div>
      //   {customroutes}
      // </div>
     <div>
     {/* <NewFile /> */}
      <Navbar />
      <Route path='/' exact component = {Home} />
      <Route path='/Features' exact component = {Features} />
      <Route path='/Contact' exact component = {Contact} />
      <Route path='/Why' exact component = {Why} />
      <Route path='/Privacy' exact component = {Privacy} />
      <Route path='/Terms' exact component = {Terms} />
      <Footer />
     </div>
    );
  }
}

export default App;

import React, { Component } from 'react'
import './privacy.css'
import {Link, NavLink} from 'react-router-dom'
import { Whatsapp } from '../Home/Whatsapp';
export class Privacy extends Component {
    render() {
        return (
            <React.Fragment>
        <div>
          <div class="ht-mega-slider">
            <div class="ht-mega-slider-activation">
              <div class="ht-slide slider-style-1 bg_image--88-feature d-flex align-items-center animation__style--1 feature-slider-style-here">
                <div class="container">
                  <div class="row align-items-center text-center">
                    <div class="col-xl-12 col-lg-12 col-md-6 col-12">
                      <div class="hero-software content hero-feature-content">
                        <h4 style={{ color: "#fff" }}>Privacy Policy</h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.{" "}
                        </p>
                        {/* <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn" href="#">
                            DOWNLOAD
                          </a>
                        </div> */}
                      </div>
                    </div>
                    {/* <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                      <div class="thumb text-right software-slide-thumb">
                        <img
                          src={require("../img/software/logo/slider.png")}
                          alt="slider"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-buy-content">
            <div className="container">
              <div className="buy-content-upper">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    
                    <div className="cookie-para">
                     
                      <ul>
                        <li>
                          {" "}
                          <strong>
                          Use of the Platform
                          </strong>
                        </li>
                        <p>Subject to these Terms, TMS grants you a personal, revocable, non-exclusive and non-transferable license during the Term to use the Platform. You are responsible for configuring the Platform and ensuring it meets the needs of your business.
</p>
                       
<li>
<strong>You will not, and will not permit anyone else to, without TMS’s prior written permission : </strong>
<ol>
    <li>use the Platform for purposes other than the Platform Services.</li>
    <li>“frame”, “mirror” or otherwise incorporate any part of Platform on any website, except as necessary to inline frame on your own website Customer-facing booking webpages provided to you by TMS.
</li>
    <li>access, monitor modify, copy or distribute any part of the Platform using any automated means or any manual process.</li>
    <li>violate the restrictions in any robot exclusion files on the Platform or circumvent other measures employed to prevent or limit access to the Platform.</li>
    <li>remove any watermarks, labels or other legal or proprietary notices included in the Platform.</li>

    <li>attempt to modify the Platform, including disguising or changing any indications of the ownership or source of the Platform.</li>
    <li>license, sublicense, resell, assign, distribute, or otherwise commercially exploit or make the Platform available to any third party, other than as expressly permitted by these Terms.
</li>
    <li>use the Platform in any manner that interferes with the integrity or performance of the Platform.</li>
    <li>use the Platform as part of any service for sharing, lending, or on behalf of any other person or entity, except to the extent permitted herein.</li>
    <li>attempt or assist others to circumvent, disable or defeat any of the security features or components that protect the Platform.</li>

    <li>create adaptations, translations, or derivative works based on the Platform or decompile, disassemble, reverse engineer or otherwise exploit any part of the Platform.</li>
    <li>use the Platform in a manner that violates the third party intellectual property rights.
</li>
    <li>upload to or transmit through the Platform anything that is offensive, hateful, obscene, defamatory or violates any laws.</li>
    <li>use the Platform to send unsolicited electronic messages (and nothing in these Terms will be construed as TMS permitting or causing you to send electronic messages using the Platform).</li>
    
</ol>
<p>TMS may deny to anyone use of the Platform at any time and for any reason. You will cease and desist from using the Platform immediately upon request by TMS.
</p>
</li>
<li><strong>Registration & System Accounts</strong></li>
<p>If you register for an account on the Platform, you agree to maintain the security and confidentiality of your password and access keys and maintain and promptly update any information you provide to TMS. You are responsible for all activities that occur through your TMS account, and for all charges incurred therefrom. Your login may only be used by one person; provided that you may create separate logins for as many people as your Subscription allows. You are responsible for ensuring all such persons adhere to these Terms.</p>
<li><strong> Data Protection, Ownership & Confidentiality</strong></li>
<p>Unless expressly stated herein, you retain all rights to data you enter in the Platform (“Your Data”). We will not modify, aggregate, rent, sell, share or disclose Your Data to third parties without your prior consent except as required by law or as expressly set forth in these Terms or our privacy policy.</p>
<br />
<p>When processing Reservations or Fees, we may disclose personal and transactional information to third party payment gateways or other associated services (see Add-ons). We may access and retain Your Data and accounts to provide services to you or to manage its business, provided that we will not retain personal information of your customers or employees or detailed financial information of your business. We may also archive Your Data for historical reporting.</p>
<br />
<p>You may export Your Data at any time. Once Your Data has left the Platform, it is no longer covered by our privacy and security policies. When your account is closed, Your Data is removed from the Platform and is no longer accessible to you.</p>
<br />
<p>Storing credit card details in the system is strictly prohibited and is contrary to our obligations with respect to the Payment Card Industry Data Security Standard (PCI DSS). You agree not to store credit card information anywhere on the Platform. To accept or process payments via the Platform, You must use one of the certified payment gateways listed on our Website.</p>

<li><strong>Hosting, Security & Availability</strong></li>
<p>TMS takes reliability and security very seriously. More information about our security technologies is available at TMS.com/security.</p>
<br />
<p>However, no system is perfectly secure or reliable and TMS does not warrant that the Platform will be secure or available. The Internet is an inherently insecure medium, and the reliability of hosting services, Internet intermediaries, your Internet service provider, and other service providers cannot be assured. TMS does not warrant or guarantee the confidentiality of any communications made by you through the Platform, that the Platform is compatible with your computer system or that the Platform, or any links therein, will be free of viruses, worms, trojan horses or disabling devices or other destructive or contaminating code.</p>
<br />
<p>You accept all of these risks and are responsible for implementing safeguards to protect the security and integrity of your computer system. You are responsible for any costs incurred by you as a result of your use of the Platform.</p>


<li><strong>Fees and Billing</strong></li>
<p>Charges for the use of the Platform (the “Fees”) are described on our website. You agree to pay all Fees (and applicable taxes) related to your use of the Platform in accordance with the payment terms set out on the Platform. TMS may change Fees and payment terms, promotions, referral programs or other fee credit programs at any time. If we change the Fees, we may give you at least 30 days’ notice, and we may apply such changes to your account at a later date. If you disagree with any such changes, do not use the Platform after the change becomes effective. TMS may also provide special pricing to certain users that is different than the publicly displayed Fees.</p>
<br />
<p>Downgrading or choosing another Subscription may cause you to lose content, features or capacity. TMS does not accept any liability for such loss.</p>

<li><strong>Customer Service & Support Submissions</strong></li>
<p>We reserve the right to change our support terms or cease to provide support at any time without notice to you. We may refuse support, or charge you for excessive requests, requests not covered by your Subscription, overly complex configurations or requests outside of the understanding of our support staff.</p>
<br />
<p>Certain Platform users may use our community support forum to post questions and find answers related to the Platform. If authorized to use the community support forum, you will use it in accordance with these Terms.</p>
<br />
<p>By submitting content to the Platform or to TMS (collectively, “Submissions”), you grant TMS a worldwide, nonexclusive, royalty-free, perpetual, transferable, irrevocable and fully sublicensable right and license to use, reproduce, modify, adapt, translate, distribute, publish, exploit, create adaptions or derivative works from and publicly display and perform such Submissions throughout the world in any media, now known or hereafter devised. You further grant TMS the right to pursue at law any person or entity that violates your or TMS’s rights in the Submissions. Submissions are non-confidential to you and may be used by TMS or shared with others without notice to you. TMS may take down any Submissions for any reason at any time without notice to you.</p>
<br />
<p>TMS takes no responsibility for your Submissions. TMS has no obligation to post your Submissions. In providing and posting Submissions, you will be solely liable for any resulting damages or harm.
If you do not agree to the terms relating to Submissions in this Section, do not provide us with any Submissions
.</p>

<li><strong> Account Cancellation and Suspension</strong></li>
<p>You are responsible for properly cancelling your account in accordance with the applicable terms on the Platform. You are responsible for any Fees charged to your account until it is properly cancelled.</p>
<br />
<p>TMS may without any notice or liability to you: (1) terminate or suspend your license to use any portion of the Platform and the rights of any third party to which you have granted access to (2) change, suspend or discontinue any part of the Platform; or (3) impose limits on your use of the Platform.</p>

<li><strong> Third-Party Sites & Services</strong></li>
<p>The Platform contains links and provides integrations to third-party web sites and services (“Third-Party Sites”). Your use of Third-Party Sites and any content or service provided therein (“Third-Party Content”) is at your own risk.</p>
<br />
<p>TMS makes no guarantee or warranty regarding Third-Party Content or Third-Party Sites, provides such links and integrations only as a convenience and accepts no responsibility for reviewing Third-Party Content or Third-Party Sites.</p>
<br />
<p>You agree to review the applicable terms and policies of any Third-Party Site and make all appropriate investigations before proceeding to or engaging with the Third-Party Site and Third-Party Content.</p>

                     
                     
                     
                     
                      </ul>
                    </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Whatsapp />
        </div>
        {/*Buy Main Content */}

        {/*end buy main content */}
      </React.Fragment>
        )
    }
}

export default Privacy

import React, { Component } from 'react'
import "./Home.css";
// import './main'
import { Link } from "react-router-dom";
export class Whatsapp extends Component {
  render() {
    return (
        <React.Fragment>
      <div>
      <div id="mybutton">
<button class="feedback"><a href={"https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"} target="_blank"><i class="lni-whatsapp"></i></a></button>
</div>
      </div>
      </React.Fragment>
    )
  }
}

export default Whatsapp

import React, { Component } from "react";
import "./Home.css";
// import './main'
import { Link } from "react-router-dom";
import { Whatsapp } from "./Whatsapp";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ui_number: ""
    };
    this.validator = new SimpleReactValidator();
  }

  change = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  async onSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        console.log(this.state);
        const response = await axios.post(
          `employe/add`,
          this.state
          // (axios.defaults.headers.common["authorization"] =
          //   "Bearer " + authService.getToken())
        );
        //   .then(console.log(this.state))
        console.log(response);
        // if (response.data.success === true) {
        //   alert(response.data.msg);

        //   this.props.history.push("/ViewEmploye");
        // }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="wrapper">
          <div class="ht-mega-slider">
            <div class="ht-mega-slider-activation">
              <div class="ht-slide slider-style-1 bg_image--88 d-flex align-items-center animation__style--1 custom-slider-style-here">
                <div class="container">
                  <div class="row align-items-center text-left">
                    <div class="col-xl-7 col-lg-7 col-md-6 col-12">
                      <div class="hero-software content">
                        <h4 style={{ color: "#fff" }}>
                          Managing bookings just became a piece of cake
                        </h4>
                        <p>
                          With our brilliant booking, tour management, enquiry,
                          customer, and employee management system, every
                          business gets all the tools they need in a single
                          software.{" "}
                        </p>
                        <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn custom-cln-btn" href="#">
                            {/* <ion-icon name="cloud-download" size="large"></ion-icon>&nbsp;&nbsp;   */}
                            Sign Up - It's Free
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                      <div class="thumb text-right software-slide-thumb">
                        <img
                          src={require("../img/software/logo/slider.png")}
                          alt="slider"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <main className="page-content">
            {/*features here */}
            <section className="custom-features-section">
              <div class="section-service custom-bg-color-features">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h4>About App</h4>

                        <p>
                          TMS is a Tourism Management System that is a
                          cloud-based e-commerce platform that is used for
                          making bookings and managing them to ensure smooth and
                          fruitful event management for every business. Through
                          our extensive feature library which includes enquiry
                          management, booking creation and management, customer
                          care, sales and promotion, tour package generation and
                          support, your business can thrive as you provide your
                          customers with the bookings they require seamlessly.
                          With our expense manager and report generator, you can
                          examine trends in enquiry conversion, customer
                          satisfaction, demand for tour packages, and more with
                          just a click of your fingers!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="custom-feature-header">
                  <div class="row mt--40">
                    <div className="col-12">
                      <div class="section-title--15 text-center">
                        <h4>SALIENT FEATURES</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                      <Link to="/Features">
                        <div class="service text-left service-6">
                          <div class="custom-lice-icons-home">
                            {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}

                            <i class="lni-ux" />
                          </div>
                          <div class="content">
                            <h5>Attendance Management</h5>
                            <p>
                              A bimoetric attendance system incorporated with
                              the attendance manual.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                      <Link to="/Features">
                        <div class="service text-left service-6">
                          <div class="custom-lice-icons-home">
                            <i class="lni-support" />
                          </div>
                          <div class="content">
                            <h5>Enquiry Management</h5>
                            <p>
                              This module helps the institute manage enquiries
                              such as course required, contact details.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                      <Link to="/Features">
                        <div class="service text-left service-6">
                          <div class="custom-lice-icons-home">
                            <i class="lni-envelope" />
                          </div>
                          <div class="content">
                            <h5>SMS Management</h5>
                            <p>
                              The institute can manage the messages sent to the
                              student and parents.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center center-block">
                      <Link to="/Features">
                        {" "}
                        <button className="btn btn-primary custom-read-btn">
                          Read More&nbsp;&nbsp;
                          <i class="fa fa-arrow-right" aria-hidden="true" />
                        </button>
                      </Link>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end features here */}
            {/*feature modal popup section */}
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Modal title
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">...</div>
                </div>
              </div>
            </div>
            {/*end feature modal popup section */}
            {/*benefit section */}
            <section className="custom-benefit-here">
              <div className="st-benift-area section-ptb-120 bg_color--2 custom-benefit-bg-color">
                <div className="container">
                  <div className="row st-benft-inner align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="thumb text-center">
                        <img
                          src={require("../img/software/others/software-1.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 sm-mt-30 md-mt-30">
                      <div className="content">
                        <h2>
                          <span>Benefit’s</span> of working with us.
                        </h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididu ut labore et
                          dolore magna aliqua. Ut enim ad min veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit.
                        </p>
                        <ul className="benifits-list">
                          <li>
                            <i className="fa fa-arrow-right" />
                            There are many variations of passages of
                          </li>
                          <li>
                            <i className="fa fa-arrow-right" />
                            There are many variations of passages of
                          </li>
                          <li>
                            <i className="fa fa-arrow-right" />
                            There are many variations of passages of
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end benefit section */}
            {/*overview section */}
            <section className="custom-overview">
              <div className="st-overview section-ptb-120 custom-bg-color-software">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title--15 text-center">
                        <h4>Software Overview</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt--60">
                    <div className="col-lg-12">
                      <div
                        id="carouselExampleSlidesOnly"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img
                              class="d-block w-100"
                              src={require("../img/software/screenshot/screen-1.png")}
                              alt="First slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              class="d-block w-100"
                              src={require("../img/software/screenshot/screen-1.png")}
                              alt="Second slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              class="d-block w-100"
                              src={require("../img/software/screenshot/screen-1.png")}
                              alt="Third slide"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt--60">
                        <div className="col-lg-12">

                            <div className="st-screenshot-wrapper software-activation">

                                <div className="st-screenshot">
                                    <img src={require("../img/software/screenshot/screen-1.png")} alt="screenshot"/>
                                </div>

                                <div className="st-screenshot">
                                    <img src={require("../img/software/screenshot/screen-1.png")} alt="screenshot" />
                                </div>

                                <div className="st-screenshot">
                                    <img src={require("../img/software/screenshot/screen-1.png")} alt="screenshot" />
                                </div>

                            </div>

                        </div>
                    </div> */}
                </div>
              </div>
            </section>
            {/*end overview section */}
            {/*how it works section */}
            <section className="custom-how-it-works">
              <div class="section-process section-ptb-120 custom-bg-color-how">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h4>How It Work</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mt--20">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 single-work custom-single-work">
                      <div class="ht-process-2">
                        <div class="number">
                          <span>01</span>
                        </div>
                        <div class="content">
                          <h4>Sign Up</h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit, sed do eiusmod tempor incid idunt ut labore{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 single-work custom-single-work">
                      <div class="ht-process-2">
                        <div class="number">
                          <span>02</span>
                        </div>
                        <div class="content">
                          <h4>Add Users</h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit, sed do eiusmod tempor incid idunt ut labore{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 single-work custom-single-work">
                      <div class="ht-process-2">
                        <div class="number">
                          <span>03</span>
                        </div>
                        <div class="content">
                          <h4>Go Ahead</h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit, sed do eiusmod tempor incid idunt ut labore{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end how it works section */}
            {/*FAQ section */}
            <section className="custom-overview custom-faq-padding">
              <div className="st-overview section-ptb-120 custom-bg-color-software">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title--15 text-center">
                        <h4>FAQ</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <h3>Frequently Asked Questions</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row mt--60">
                    <div className="col-lg-12">
                      <div class="bs-example custom-bs-example">
                        <div class="accordion" id="accordionExample">
                          <div class="card">
                            <div
                              class="card-header"
                              id="headingOne"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                            >
                              <h2 class="mb-0">
                                <button type="button" class="btn btn-link">
                                  <i class="fa fa-plus" /> How do my customers
                                  book?
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseOne"
                              class="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div class="card-body">
                                <p>
                                  TMS offers easy customer bookings online with
                                  minimal hassle. Our booking page manages
                                  bookings and schedules for your business and
                                  your customers can book through your own
                                  website with our plugins. You can also accept
                                  and manage bookings through social media or
                                  use our booking page templates.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div
                              class="card-header"
                              id="headingTwo"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                            >
                              <h2 class="mb-0">
                                <button
                                  type="button"
                                  class="btn btn-link collapsed"
                                >
                                  <i class="fa fa-plus" /> How else can I access
                                  TMS ?
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseTwo"
                              class="collapse"
                              aria-labelledby="headingTwo"
                              data-parent="#accordionExample"
                            >
                              <div class="card-body">
                                <p>
                                  We are a platform independent service which
                                  means that you can access our bookings and
                                  manage them through a tablet, mobile app, or
                                  desktop.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div
                              class="card-header"
                              id="headingThree"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                            >
                              <h2 class="mb-0">
                                <button
                                  type="button"
                                  class="btn btn-link collapsed"
                                >
                                  <i class="fa fa-plus" /> Is support going to
                                  be more tedious ?
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseThree"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordionExample"
                            >
                              <div class="card-body">
                                <p>
                                  Support is crucial to your business and we
                                  provide our highest levels of support in our
                                  plans. We have a super detailed help section
                                  with a vast number of follow-ups to give you
                                  everything you need. We don’t leave our
                                  customers to fend for themselves after their
                                  product is launched; we stay till the very
                                  end.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end FAQ section */}
            {/*testimonial */}
            <section className="custom-testimonials">
              <div className="section-testimonial section-ptb-120 bg_color--2">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title--15 text-center">
                        <h4>Thousands of Smiles</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt--10">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="card col-md-10 mt-2">
                        <div
                          id="carouselExampleControls"
                          class="carousel slide"
                          data-ride="carousel"
                          data-interval="100000"
                        >
                          <div class="w-100 carousel-inner" role="listbox">
                            <div class="carousel-item active">
                              <div class="bg" />
                              <div class="carousel-caption">
                                <div class="row">
                                  <div class="col-sm-2">
                                    <img
                                      src="https://gravatar.com/avatar/b35505a8799ee6ec3cba72ac4c5185be?s=80&d=https://codepen.io/assets/avatars/user-avatar-80x80-bdcd44a3bfb9a5fd01eb8b86f9e033fa1a9897c3a15b33adfc2649a002dab1b6.png"
                                      alt=""
                                      class="rounded-circle"
                                    />
                                  </div>
                                  <div class="col-sm-10">
                                    <p>
                                      Customer enquiries can be easily updated
                                      and accessed, the simple UI helps in
                                      keeping track of enquiries with
                                      simplicity.
                                    </p>

                                    <em>
                                      {" "}
                                      <small class="smallest mute">
                                        {" "}
                                        <strong>-Customer</strong>
                                      </small>
                                    </em>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="carousel-item">
                              <div class="bg" />
                              <div class="carousel-caption">
                                <div class="row">
                                  <div class="col-sm-2">
                                    <img
                                      src="https://gravatar.com/avatar/b35505a8799ee6ec3cba72ac4c5185be?s=80&d=https://codepen.io/assets/avatars/user-avatar-80x80-bdcd44a3bfb9a5fd01eb8b86f9e033fa1a9897c3a15b33adfc2649a002dab1b6.png"
                                      alt=""
                                      class="rounded-circle"
                                    />
                                  </div>
                                  <div class="col-sm-10">
                                    <p>
                                      The management systems have been
                                      instrumental in maintaining the pace of
                                      the company and the employee performance
                                      can be tracked through reports. TMS keeps
                                      things running smoothly.
                                    </p>

                                    <em>
                                      {" "}
                                      <small class="smallest mute">
                                        {" "}
                                        <strong>- Business 1</strong>
                                      </small>
                                    </em>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="carousel-item">
                              <div class="bg" />
                              <div class="carousel-caption">
                                <div class="row">
                                  <div class="col-sm-2">
                                    <img
                                      src="https://gravatar.com/avatar/b35505a8799ee6ec3cba72ac4c5185be?s=80&d=https://codepen.io/assets/avatars/user-avatar-80x80-bdcd44a3bfb9a5fd01eb8b86f9e033fa1a9897c3a15b33adfc2649a002dab1b6.png"
                                      alt=""
                                      class="rounded-circle"
                                    />
                                  </div>
                                  <div class="col-sm-10">
                                    <p>
                                      Bookings have become more organized
                                      through the booking management and tour
                                      package system. We were able to customize
                                      our own tours according to the demand from
                                      every branch with ease.
                                    </p>

                                    <em>
                                      {" "}
                                      <small class="smallest mute">
                                        {" "}
                                        <strong>- Business 2</strong>
                                      </small>
                                    </em>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                            <i class="fa fa-chevron-left" />
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                            <i class="fa fa-chevron-right" />
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      {/* <div class="demo">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div id="testimonial-slider" class="owl-carousel custom-title-position">
                                <div class="testimonial">
                                  <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Phasellus molestie, justo
                                    nec convallis sollicitudin, sapien lorem
                                    dictum lacus, non consequat odio ipsum nec
                                    est. Sed mattis egestas elementum. Nulla
                                    facilisi. Quisque placerat risus ac nunc
                                    ornare tincidunt. Sed quis faucibus nunc.
                                    Pellentesque accumsan arcu mi, eget
                                    venenatis mauris.
                                  </p>
                                  <h3 class="title ">williamson</h3>
                                  <span class="post">Web Developer</span>
                                </div>
                                <div class="testimonial">
                                  <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Phasellus molestie, justo
                                    nec convallis sollicitudin, sapien lorem
                                    dictum lacus, non consequat odio ipsum nec
                                    est. Sed mattis egestas elementum. Nulla
                                    facilisi. Quisque placerat risus ac nunc
                                    ornare tincidunt. Sed quis faucibus nunc.
                                    Pellentesque accumsan arcu mi, eget
                                    venenatis mauris.
                                  </p>
                                  <h3 class="title">Kristina</h3>
                                  <span class="post">Web Designer</span>
                                </div>

                                <div class="testimonial">
                                  <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Phasellus molestie, justo
                                    nec convallis sollicitudin, sapien lorem
                                    dictum lacus, non consequat odio ipsum nec
                                    est. Sed mattis egestas elementum. Nulla
                                    facilisi. Quisque placerat risus ac nunc
                                    ornare tincidunt. Sed quis faucibus nunc.
                                    Pellentesque accumsan arcu mi, eget
                                    venenatis mauris.
                                  </p>
                                  <h3 class="title">Miranda Joy</h3>
                                  <span class="post">Web Developer</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title--15 text-center">
                                <h2>Thousands of Smiles</h2>
                                <img src={require("../img/software/logo/title.png")} alt="title" />
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt</p>
                            </div>
                        </div>
                    </div> */}
                  {/* <div className="row mt--10">
                        <div className="col-lg-12">
                            <div className="testimonial-style-5 st-software testimonial-slider-2 poss--relative">

                                
                                <div className="testimonal-nav">

                                    <div className="testimonal-img">
                                        <img src={require("../img/testimonial/clint-9.png")} alt="testimonal image" />
                                    </div>

                                    <div className="testimonal-img">
                                        <img src={require("../img/testimonial/clint-10.png")} alt="testimonal image" />
                                    </div>

                                    <div className="testimonal-img">
                                        <img src={require("../img/testimonial/clint-11.png")} alt="testimonal image" />
                                    </div>

                                    <div className="testimonal-img">
                                        <img src={require("../img/testimonial/clint-12.png")} alt="testimonal image" />
                                    </div>
                                </div>
                               
                                <div className="testimonial-for">

                                    <div className="testimonial-desc">
                                        <div className="triangle"></div>
                                        <div className="client">
                                            <h6>Carolina Montoya</h6>
                                            <ul className="rating">
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star-half-o"></i></li>
                                            </ul>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod
                                            tempor incididunt ut labore et dolore Lorem ipsum dolor sit amet,
                                            consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                            dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-desc">
                                        <div className="triangle"></div>
                                        <div className="client">
                                            <h6>Michelle Mitchell</h6>
                                            <ul className="rating">
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star-half-o"></i></li>
                                            </ul>
                                        </div>
                                        <p>Nipa ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor
                                            incididunt ut labore et dolore Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-desc">
                                        <div className="triangle"></div>
                                        <div className="client">
                                            <h6>Ryan Garcia</h6>
                                            <ul className="rating">
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star-half-o"></i></li>
                                            </ul>
                                        </div>
                                        <p>Np ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor
                                            incididunt ut labore et dolore Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua. </p>
                                    </div>
                                    <div className="testimonial-desc">
                                        <div className="triangle"></div>
                                        <div className="client">
                                            <h6>Klaus Gruber</h6>
                                            <ul className="rating">
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star-half-o"></i></li>
                                            </ul>
                                        </div>
                                        <p>Supa ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor
                                            incididunt ut labore et dolore Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua. </p>
                                    </div>

                                </div>
                               



                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </section>
            {/*end testimonial */}
            {/*stats page */}
            <section className="custom-stats-section">
              <div class="section-service">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div class="custom-stats-para text-center">
                        <h3>2015</h3>
                        <p>Created</p>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div class="custom-stats-para text-center">
                        <h3>100%</h3>
                        <p>Self - funded</p>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div class="custom-stats-para text-center">
                        <h3>100,000</h3>
                        <p>Users</p>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div class="custom-stats-para text-center">
                        <h3>Pune</h3>
                        <p>Based</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end stats page*/}
            {/*plans section */}
            <section className="custom-plan-section">
              <div class="section-pricing-table section-ptb-120 custom-bg-color-plans">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h4>Best Plans For You</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center mt--30">
                    {/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div class="pricing-panel pricing-panel-4">
                        <div class="pricing-heading">
                          <div class="title">
                            <h2>Standered</h2>
                          </div>
                          <div class="price">
                            <h4>
                              <sub>$</sub>99.00
                            </h4>
                          </div>
                        </div>
                        <div class="pricing-body">
                          <ul>
                            <li>Eantry,</li>
                            <li>Normal Seat,</li>
                            <li>T-shirt,</li>
                            <li>Single Day.</li>
                            <li>Some Features Also, </li>
                            <li>Free Coffee.</li>
                          </ul>
                        </div>
                        <div class="pricing-footer">
                          <a class="price_btn" href="#">
                            Buy Ticket
                          </a>
                        </div>
                      </div>
                    </div> */}

                    <div class="offset-md-2 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div class="pricing-panel pricing-panel-4 ">
                        <div class="pricing-heading">
                          <div class="title">
                            <h2>Professional</h2>
                          </div>
                          <div class="price">
                            <h4>
                              <sub>$</sub>100.00
                            </h4>
                          </div>
                        </div>
                        <div class="pricing-body">
                          <ul>
                            <li>Eantry,</li>
                            <li>Normal Seat,</li>
                            <li>T-shirt,</li>
                            <li>Single Day.</li>
                            <li>Some Features Also, </li>
                            <li>Free Coffee.</li>
                          </ul>
                        </div>
                        <div class="pricing-footer">
                          <a class="price_btn" href="#">
                            Buy Ticket
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div class="pricing-panel pricing-panel-4 active st-active custom-active-price">
                        <div class="pricing-heading">
                          <div class="title">
                            <h2>Premium</h2>
                          </div>
                          <div class="price">
                            <h4>
                              <sub>$</sub>200.00
                            </h4>
                          </div>
                        </div>
                        <div class="pricing-body">
                          <ul>
                            <li>Eantry,</li>
                            <li>Normal Seat,</li>
                            <li>T-shirt,</li>
                            <li>Single Day.</li>
                            <li>Some Features Also, </li>
                            <li>Free Coffee.</li>
                          </ul>
                        </div>
                        <div class="pricing-footer">
                          <a class="price_btn" href="#">
                            Buy Ticket
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end plans section */}
            {/*clients here */}
            <section className="custom-clients-here">
              <div class="section-brand section-ptb-120 custom-bg-color-clients">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <section class="customer-logos slider">
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-1.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-2.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-3.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-4.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-5.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-2.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-3.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-4.png")}
                            alt="brand image"
                          />
                        </div>
                        <div class="slide">
                          <img
                            src={require("../img/brand/agency/brand-5.png")}
                            alt="brand image"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end clients here */}

            {/*subscribe here*/}
            {/* <section className="custom-subscribe">
              <div className="section-newsletter section-ptb-120 bg_image--6">
                <div className="container">
                  <div className="row newsletter-style-4 align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="title">
                        <p>Sign Up Here to get Letest Update</p>
                        <h2>Subscribe to Newsletter.</h2>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <form action="#">
                        <div className="input-box">
                          <input type="email" placeholder="Enter Your Email*" />
                          <button type="submit">
                            <i className="fa fa-paper-plane-o" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/*end subscribe*/}
            {/*free */}
            {/* <section className="custom-plan-section">
              <div class="section-pricing-table section-ptb-120 custom-bg-color-plans2">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h2>Free Forever upto 200 Students</h2>
                        <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        />
                        <p>
                        Always
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center mt--30">

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center center-block ">
                        <div className="free-btn">
                        <button type="submit" class="btn btn-primary">Sing Up - It's Free!</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/*end free */}
            {/*contact section */}
            <section className="custom-clients-here">
              <div class="section-brand section-ptb-120 custom-bg-color-clients">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div className="section-title--15 text-center">
                        <h4>Contact Us</h4>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        /> */}
                        <p>Find Us By Below Detail</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row mt--40 custom-detail-href">
                        <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                          <div
                            className="custom-contact-detail-sec custom-service-6-tms-home text-center center-block"
                            data-toggle="modal"
                            data-target="#numberModal"
                          >
                            <div className="custom-detail-icons">
                              <i class="lni-mobile" />
                            </div>
                            <br />
                            <br />
                            <p>Get A Call Back From Us</p>
                          </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                          <div className="custom-contact-detail-sec custom-service-6-tms-home text-center center-block">
                            <div className="custom-detail-icons">
                              <i class="lni-phone-handset" />
                            </div>
                            <br />
                            <br />
                            <p>
                              {" "}
                              +65 93901336 <br />
                              +65 93901337
                            </p>
                          </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                          <div className="custom-contact-detail-sec custom-service-6-tms-home text-center center-block">
                            <div className="custom-detail-icons">
                              <i class="lni-envelope" />
                            </div>
                            <br />
                            <br />
                            <p>
                              yourmail@gmail.com <br />
                              backpiper.com@gmail.com
                            </p>
                          </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                          <a
                            href={
                              "https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"
                            }
                            target="_blank"
                          >
                            <div className="custom-contact-detail-sec custom-service-6-tms-home text-center center-block">
                              <div className="custom-detail-icons">
                                <i class="lni-whatsapp" />
                              </div>
                              <br />
                              <br />
                              <p>9175514982</p>
                            </div>
                          </a>
                        </div>

                        {/* 
                       

                        <div class="col-lg-3 col-md-3 col-sm-12 col-12 text-center">
                          <div class="service text-left service-6 custom-service-contact-box custom-service-6">
                            <div className="row">
                              <div className="offset-md-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center center-block">
                                <a
                                  href={
                                    "https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"
                                  }
                                  target="_blank"
                                >
                                  {" "}
                                  <div class="icons">
                                    <i
                                      class="fa fa-whatsapp"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div class="content text-center">
                              <a
                                href={
                                  "https://api.whatsapp.com/send?phone=919175514982&text=Hi%20we%20need%20help%20regarding%20something"
                                }
                                target="_blank"
                              >
                                {" "}
                                <p>9175514982</p>
                              </a>
                            </div>
                          </div>
                        </div> */}
                        {/*contact modal */}
                        <div
                          class="modal fade"
                          id="numberModal"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="numberModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="numberModalLabel">
                                  Modal title
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form>
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Contact Number
                                    </label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Contact Number"
                                      name="ui_number"
                                      value={this.state.ui_number}
                                      onChange={e => this.change(e)}
                                    />
                                    <span className="text-danger">
                                      {this.validator.message(
                                        "Mobile Number",
                                        this.state.ui_number,
                                        "required|numeric|min:10|max:10"
                                      )}
                                    </span>
                                    <small
                                      id="emailHelp"
                                      class="form-text text-muted"
                                    >
                                      Our Team Will Contact You Soon.
                                    </small>
                                  </div>

                                  <button
                                    type="submit"
                                    class="btn btn-primary"
                                    onClick={e => this.onSubmit(e)}
                                  >
                                    Submit
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*end modal */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*free */}
            <section className="custom-plan-section">
              <div class="section-pricing-table custom-bg-color-plans2">
                <div class="container">
                  <div class="row">
                    <div class="offset-lg-3 col-lg-3">
                      <div class="text-center">
                        <p className="free-para">
                          <strong>Free Forever upto 500 Customers</strong>
                        </p>
                        {/* <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        />
                        <p>
                        Always
                        </p> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="free-btn">
                        <button type="submit" class="btn btn-primary free-forever-btn">
                          Sign Up - It's Free!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end free */}
            <Whatsapp />
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;

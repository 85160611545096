import React, { Component } from "react";
import "./why.css";
import { Link, NavLink } from "react-router-dom";
import { Whatsapp } from "../Home/Whatsapp";
export class Why extends Component {
  render() {
    return (
      <div>
        <div id="wrapper" className="wrapper">
          <div class="ht-mega-slider">
            <div class="ht-mega-slider-activation">
              <div class="ht-slide slider-style-1 bg_image--88-feature d-flex align-items-center animation__style--1 feature-slider-style-here">
                <div class="container">
                  <div class="row align-items-center text-center">
                    <div class="col-xl-12 col-lg-12 col-md-6 col-12">
                      <div class="hero-software content hero-feature-content">
                        <h4 style={{ color: "#fff" }}>Why Us</h4>
                        {/* <p>
                         
                        </p> */}
                        {/* <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn" href="#">
                            DOWNLOAD
                          </a>
                        </div> */}
                      </div>
                    </div>
                    {/* <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                      <div class="thumb text-right software-slide-thumb">
                        <img
                          src={require("../img/software/logo/slider.png")}
                          alt="slider"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*main page */}
          <main className="page-content">
            {/*features here */}
            <section className="custom-features-section">
              <div class="section-service custom-bg-color-features custom-bg-color-why">
                <div class="container">
                  {/* <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h2>Our Features</h2>
                        <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        />
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div class="row mt--40 custom-margin-top-feature">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="mission-container text-center">
                        <div class="section-title--15 text-center">
                          <h4>Why Us</h4>

                          <p>
                          As a Tourism Management System, we depict our passion
                          for the travel and tourism industry through our work.
                          We see customer satisfaction as the main goal and our
                          simple user interface, customer care, support, and
                          management of several modules is a testimonial of
                          increasing customer rates. Businesses utilize our
                          services in order to manage their customer bookings
                          and communicate with them prior to the event. Our
                          report generator and expense manager help businesses
                          track their customer behaviour and employee
                          performance. We help to make booking and scheduling
                          faster and easier. Businesses can customize their tour
                          packages based on local trends and demand and also
                          provide promotional follow-up services that can help
                          in managing their customer relationships.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*end features here */}

            <Whatsapp />
          </main>

          {/*end main page */}
        </div>
      </div>
    );
  }
}

export default Why;

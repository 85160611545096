import React, { Component } from "react";
import "./feature.css";
// import './main'
import { Link } from "react-router-dom";
import { Whatsapp } from "../Home/Whatsapp";
export class Features extends Component {
  render() {
    return (
      <div>
        <div id="wrapper" className="wrapper">
          <div class="ht-mega-slider">
            <div class="ht-mega-slider-activation">
              <div class="ht-slide slider-style-1 bg_image--88-feature d-flex align-items-center animation__style--1 feature-slider-style-here">
                <div class="container">
                  <div class="row align-items-center text-center">
                    <div class="col-xl-12 col-lg-12 col-md-6 col-12">
                      <div class="hero-software content hero-feature-content">
                        <h4 style={{ color: "#fff" }}>Our Features</h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.{" "}
                        </p>
                        {/* <div class="slide-btn custom-download-slider-btn">
                          <a class="cln-btn" href="#">
                            DOWNLOAD
                          </a>
                        </div> */}
                      </div>
                    </div>
                    {/* <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                      <div class="thumb text-right software-slide-thumb">
                        <img
                          src={require("../img/software/logo/slider.png")}
                          alt="slider"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*main page */}
          <main className="page-content">
            {/*features here */}
            <section className="custom-features-section">
              <div class="section-service custom-bg-color-features">
                <div class="container">
                  {/* <div class="row">
                    <div class="col-lg-12">
                      <div class="section-title--15 text-center">
                        <h2>Our Features</h2>
                        <img
                          src={require("../img/software/logo/title.png")}
                          alt="title"
                        />
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit sed do eiusmod tempor incididunt
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div class="row mt--40 custom-margin-top-feature">
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-ux" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Enquiry section</strong>
                          </span>
                          <p>
                            A satisfied customer leads to a happy customer and
                            consequently, a profitable company.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#enquiry"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-support" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Booking section</strong>
                          </span>
                          <p>
                            Our passion for the travel and tourism industry has
                            led us to create a system.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#course"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-book" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Customers</strong>
                          </span>
                          <p>
                            Customers can create their own accounts through the
                            website generated .&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>

                    {/*second row */}
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#staff"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-users" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Tour packages</strong>
                          </span>
                          <p>
                            Our tour package system will help our tour providers
                            create the standard tour package.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#lecture"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-shuffle" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Support tickets</strong>
                          </span>
                          <p>
                            Managing a tourism company can be very complex and
                            most providers require support.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#admission"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-rupee" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Expenses </strong>
                          </span>
                          <p>
                            An expense tracking system can be utilized by
                            businesses to keep track of the
                            salaries.&nbsp;&nbsp;<i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>

                    {/*third row */}
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#fees"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}

                          <i class="lni-clipboard"></i>
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Reports</strong>
                          </span>
                          <p>
                            Obtaining advanced business reports based on data
                            acquisition of bookings.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#website"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-website" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Automated Billing and Invoices</strong>
                          </span>
                          <p>
                            Companies and businesses can generate automatic
                            bills and invoices.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#dashboard"
                      >
                        <div class="text-center center-block custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-dashboard" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Payment Gateway Integration</strong>
                          </span>
                          <p>
                            Payment gateway integration can be done with the
                            company’s website.&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>

                    {/*final row */}
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div
                        class="service text-left service-6 custom-service-box custom-service-6"
                        data-toggle="modal"
                        data-target="#test"
                      >
                        <div class="text-center custom-lice-icons">
                          {/* <img
                            src={require("../img/service/icon/icon10.png")}
                            alt="service icons"
                          /> */}
                          {/* <i class="fa fa-ravelry" aria-hidden="true"></i> */}
                          <i class="lni-question-circle" />
                        </div>
                        <div class="content custom-content-features-arrow">
                          <span className="first-header-para">
                            <strong>Free website</strong>
                          </span>
                          <p className="text-center center-block custom-last-row-para-feature">
                            We offer free website services which can be used by
                            our clients .&nbsp;&nbsp;
                            <i class="lni-arrow-right" />
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div class="service text-left service-6">
                        <div class="icons">
                          <img
                            src={require("../img/service/icon/icon11.png")}
                            alt="service icons"
                          />
                        </div>
                        <div class="content">
                          <h4>Hardware Wallets</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consec turlol adip sicin
                            loss elit, sed do eius tempor incididunt.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div class="service text-left service-6">
                        <div class="icons">
                          <img
                            src={require("../img/service/icon/icon12.png")}
                            alt="service icons"
                          />
                        </div>
                        <div class="content">
                          <h4>Mobility & Security</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consec turlol adip sicin
                            loss elit, sed do eius tempor incididunt.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            {/*end features here */}
            {/*feature modal popup section */}
            <div className="custom-modal">
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Enquiry Section
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      A satisfied customer leads to a happy customer and
                      consequently, a profitable company. We believe in keeping
                      customers satisfied and tour providers happy, from the
                      beginning. Our well-furbished enquiry management system
                      gives the ammunition to tourism and activity businesses to
                      deal with customer enquiries efficiently. We provide the
                      tools which best fit each tour provider and help them
                      solve each customer enquiry so that new leads are
                      generated. With our custom lead forms which can be
                      embedded in our websites and on other websites the
                      customer can easily generate leads which will help in
                      increasing the customer base and earning more revenue for
                      the tour provider. Our system incorporates periodic SMS
                      and email facilities that are used to follow-up on
                      enquiries and subsequently convert the customer into a new
                      lead for the company.
                    </div>
                  </div>
                </div>
              </div>

              {/*secodn model */}
              <div
                class="modal fade"
                id="enquiry"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Booking Section.
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Our passion for the travel and tourism industry has led us
                      to create a system that considers all the difficulties of
                      tourism providers. We have created a booking system that
                      stems from our determination to provide the best for our
                      clients and our innovative roots. It is important that not
                      one step is missed from the entire cycle of browsing the
                      website, to creating a lead, to starting follow-up
                      activities, to generating a sale, to communicating and
                      managing crucial tasks until the event, to accumulating
                      signatures and all related guest information and to
                      finally getting the customer’s feedback about the
                      organisation of the event. Our booking software helps the
                      customers of our clients in booking a tour package of
                      their choice while our clients can seamlessly access the
                      list of bookings, schedule the trips, access their
                      customer list for a specific branch, and review a booking
                      that has been cancelled and the refund status of the same.
                      With a process that is this smooth and flowing, there is a
                      guaranteed win for our clients.
                    </div>
                  </div>
                </div>
              </div>

              {/*third model */}
              <div
                class="modal fade"
                id="course"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Customers
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Customers can create their own accounts through the
                      website generated by us and obtain notifications for
                      upcoming trips, exciting new offers, and tour packages in
                      their area which fit their pockets. They can also
                      reschedule or cancel appointments using the simple UI of
                      their personal account.
                      <br />
                      <br />
                      Using our services, the tour provider can gather customer
                      information and track their appointment history. Their
                      profiles can be created and managed and the tour provider
                      can get an insight into their customers’ behaviour through
                      our reports and patterns generated for each of them. This
                      can help the company in comprehending the tour packages
                      that are in demand and make more provisions regarding the
                      same. The tour provider can then manage scheduling and
                      promotion based on customer history and also provide
                      offers to attract new customers. Through promotional codes
                      and referrals, the company can reel in returning customers
                      and first-time customers and these tools are provided by
                      our website.
                    </div>
                  </div>
                </div>
              </div>

              {/*third model */}
              <div
                class="modal fade"
                id="staff"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Tour Packages
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Our tour package system will help our tour providers
                      create the standard tour package provided by their company
                      and also make changes based on popular demand,
                      effortlessly. The admin can add multiple services to one
                      booking which leads to proficient management of planning
                      and charges which will ensure that the tour provider is
                      always one step ahead. Our dashboard provides every detail
                      that the tour provider needs for managing bookings and
                      charges which ultimately helps in the management of
                      resources so that the guests are never dissatisfied. Tour
                      packages can be created based on the rules of the company
                      which can be enforced since the beginning.
                    </div>
                  </div>
                </div>
              </div>

              {/*fourth model */}
              <div
                class="modal fade"
                id="lecture"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Support Tickets
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Managing a tourism company can be very complex and most
                      providers require support that is channeled by a passion
                      for the travel and tourism industry with a deep
                      understanding of their business and its intricacies. Our
                      company believes in the success of our clients and we
                      provide our best support at every level so that their
                      businesses can make optimal use of our best features.
                      Through email and ticket based systems we aim to reach
                      every nook and cranny of the guest database of our clients
                      provide the support they need to tackle their customer
                      grievances with a smile.
                    </div>
                  </div>
                </div>
              </div>

              {/*fifth model */}
              <div
                class="modal fade"
                id="admission"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Expenses
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      An expense tracking system can be utilized by businesses
                      to keep track of the salaries of their employees, rent,
                      booking payments and deliverables, taxes, and promotion
                      funds. We have designed a system that is wholesome and
                      integrates all the crucial expenditure and income of the
                      business and keeps a detailed account of the same. The
                      reports generated by the system shows trends in increasing
                      or decreasing expenditure that can help the business
                      manage their expenses with ease.
                    </div>
                  </div>
                </div>
              </div>

              {/*sizth model */}
              <div
                class="modal fade"
                id="fees"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Reports
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Obtaining advanced business reports based on data
                      acquisition of bookings, payments, employees, clients, and
                      travel destinations is a piece of pie with our report
                      generator. The report can track branch performance,
                      enquiry conversion rates, demographics of enquiries,
                      customer trends, expense reports, and tour package
                      performance. We employ business intelligence and data
                      analytics to provide trends in customer behaviour and
                      branch performance so that our clients can get meaningful
                      insights into the current demand for travel and activity
                      tourism and manage their business processes accordingly.
                      These reports can be generated at any time and any place
                      as we aim to reduce complexity and increase productivity
                      in their tourism business .
                    </div>
                  </div>
                </div>
              </div>

              {/*seventh model */}
              <div
                class="modal fade"
                id="website"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Automated Billing and Invoices
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Companies and businesses can generate automatic bills and
                      invoices for tours, appointments, classes, and tour
                      packages through our automated billing and invoice
                      generation system. These invoices can be sent to customers
                      by email or SMS and customers can log in to their accounts
                      and check the dues.
                    </div>
                  </div>
                </div>
              </div>

              {/*eigth model */}
              <div
                class="modal fade"
                id="dashboard"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Payment Gateway Integration
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Payment gateway integration can be done with the company’s
                      website which will make handling invoices and billing
                      procedures easily with no hassle. Online payments can help
                      in generating customer traffic that believes in digital
                      payments and streamlining all the business processes
                      seamlessly.
                    </div>
                  </div>
                </div>
              </div>

              {/*tenth model */}
              <div
                class="modal fade"
                id="test"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Free Website
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      We offer free website services which can be used by our
                      clients to attract consumers online. With the booking,
                      tour management, enquiry, customer, and employee
                      management system all present in a single software,
                      companies can manage all business processes seamlessly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end feature modal popup section */}
            <Whatsapp />
          </main>

          {/*end main page */}
        </div>
      </div>
    );
  }
}

export default Features;
